import * as React from 'react'
import styled from 'styled-components'
import { colors, spacing, fontSize } from '@120wateraudit/envirio-components'
import { SendOption } from '.'
import { Error } from 'src/components/Error'

interface Props {
  sendOption: SendOption
  numberOfLetters: number
  error?: any
}

export const ConfirmSend = ({ sendOption, numberOfLetters, error }: Props) => (
  <Wrapper>
    <CountLabel>{getText(sendOption, numberOfLetters)}</CountLabel>
    {error && <Error error={error} />}
  </Wrapper>
)

const getText = (sendOption: SendOption, numberOfLetters: number) => {
  switch (sendOption) {
    case SendOption.USPS:
      return (
        <>
          You are about to send <b>{numberOfLetters} Results Letters</b> via
          USPS.
        </>
      )
    case SendOption.PDF:
      return (
        <PDFConfirmation>
          You have <b>{numberOfLetters} Results Letters</b> ready to download.
        </PDFConfirmation>
      )
    default:
      return
  }
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const CountLabel = styled.div`
  color: ${colors.black50};
  padding-bottom: ${spacing.tiny};
  font-size: ${fontSize.body};
`

const PDFConfirmation = styled.div`
  text-align: center;
`
