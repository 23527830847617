import { FilterType } from '@120wateraudit/envirio-components'
import { PWSProgram } from '@120wateraudit/envirio-components/dist/models'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import ProgramActions from 'src/actions/programs'
import SkuActions from 'src/actions/skus'
import { useData, usePrograms } from 'src/hooks'
import { ApplicationState } from 'src/reducers'
import { useAccount } from 'src/router/UserProvider'
import { getProgram } from 'src/selectors/programs'
import { getSkusHaveFetched } from 'src/selectors/skus'
import { ProgramTypeName } from 'src/types/Programs'
import {
  ProgramTotals,
  ReplacementProgramStatistics
} from 'src/types/Statistics'
import {
  useGetAllProgramTypesQuery,
  useGetProgramLocationStatusOptionsQuery,
  useGetPurchasedProgramTypesQuery
} from './dataAccess'

interface RouteParams {
  active: string
  id: string
}

export const useProgramDetails = () => {
  const { id: programId } = useParams<RouteParams>()
  const { id: accountId } = useAccount()
  const dispatch = useDispatch()
  const hasSkus = useSelector(getSkusHaveFetched)
  useEffect(() => {
    dispatch(
      ProgramActions.detailsActions.fetchRequest({ accountId, programId })
    )
    if (!hasSkus) {
      dispatch(SkuActions.collectionActions.fetchRequest({ accountId }))
    }
    // We are leaving out the `hasSkus` dep here to avoid refetching when we don't need to.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, accountId, programId])
  const program = useSelector(getProgram)
  const loading = useSelector(
    (state: ApplicationState) => state.program.isFetching
  )
  const actualProgram = Array.isArray(program)
    ? undefined
    : (program as PWSProgram)
  return {
    loading: loading || actualProgram?.id !== parseInt(programId, 10),
    program: actualProgram
  }
}

export const useProgramsFilter = () => {
  const { programs } = usePrograms()
  return useMemo(
    () => ({
      defaultValue: 'All',
      key: 'programId',
      label: 'Program',
      options: [
        { text: 'All', value: 'All' },
        ...programs.map(p => ({ text: p.name, value: p.id }))
      ],
      type: FilterType.ListSelection
    }),
    [programs]
  )
}

export const useProgramTypesFilter = () => {
  const { data: programTypes = [] } = useGetPurchasedProgramTypesQuery()
  return useMemo(
    () => ({
      defaultValue: 'All',
      key: 'programTypeId',
      label: 'Program Type',
      options: [
        { text: 'All', value: 'All' },
        ...programTypes.map(pt => ({ text: pt.name, value: pt.id }))
      ],
      type: FilterType.ListSelection
    }),
    [programTypes]
  )
}

type Stats = ProgramTotals | ReplacementProgramStatistics

export const useProgramStats = (programId: number) => {
  const { id: accountId } = useAccount()
  const { data: stats, loading } = useData<Stats>(
    `/pws/rest/accounts/${accountId}/programs/${programId}/totals`,
    undefined,
    {
      isPaginated: false
    }
  )
  return { loading, stats: stats[0] }
}

export const useProgramTypeName = (program?: PWSProgram): ProgramTypeName => {
  const { programTypeId } = program || {}
  const { data: programTypes = [] } = useGetAllProgramTypesQuery()
  return useMemo(() => {
    const programType = programTypes.find(pt => pt.id === programTypeId)
    return (programType?.name ?? 'default') as ProgramTypeName
  }, [programTypeId, programTypes])
}

export const useStatusFilter = (program?: PWSProgram) => {
  const { data: response = { data: [] } } =
    // We skip if there is no program passed in, so we won't make this call with an ID of 0
    useGetProgramLocationStatusOptionsQuery(program?.programTypeId ?? 0, {
      skip: !program
    })
  return useMemo(
    () => ({
      defaultValue: 'All',
      key: 'status',
      label: 'Status',
      options: [
        { text: 'All', value: 'All' },
        ...response.data.map(opt => ({ text: opt.name, value: opt.name }))
      ],
      type: FilterType.ListSelection
    }),
    [response.data]
  )
}
