import React from 'react'
import { colors } from '@120wateraudit/envirio-components'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as DownloadIcon } from 'src/assets/images/Download.svg'

import './DownloadStarted.css'

interface Props {
  type: 'comm' | 'export'
}

export const DownloadStartedToast = ({ type }: Props): JSX.Element => {
  const title = type === 'comm' ? 'Download Started' : 'Export Started'
  return (
    <Wrapper>
      <Header>
        <Image />
        {title}
      </Header>
      <br />
      <Information>
        <Details type={type} />
      </Information>
    </Wrapper>
  )
}

const Details = ({ type }: Props) => {
  if (type === 'comm') {
    return (
      <>
        Your PDF download has started. If it doesn't begin automatically{' '}
        <AuditLogLink to="/communications/letter-logs">
          click here to download from your Communications Letter Log.
        </AuditLogLink>
      </>
    )
  }

  return (
    <>
      Your export has started processing. When it is ready, you can download it
      from your{' '}
      <AuditLogLink to="/administration/exportlog">Export Log.</AuditLogLink>
    </>
  )
}

const Wrapper = styled.div`
  margin-right: 3rem;
`
const Header = styled.div`
  color: ${colors.black};
  padding-top: 1rem;
  padding-left: 1rem;
  font-size: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Image = styled(DownloadIcon)`
  padding-right: 0.5rem;
`
const Information = styled.div`
  color: ${colors.black75};
  padding: 1rem, 3rem, 1rem;
  font-size: 1.2rem;
`

const AuditLogLink = styled(Link)`
  color: ${colors.primary};
`
