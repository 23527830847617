import { CommunicationType } from '@120wateraudit/communications-types'
import { colors } from '@120wateraudit/envirio-components'
import { Dropdown } from '@120wateraudit/envirio-components/dist/components/Dropdown'
import React, { memo, useCallback, useMemo } from 'react'
import { CommunicationTypes } from 'src/containers/Locations/SendCommunicationButton'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { RadioComponent } from 'src/components/Radio'
import { useFeatures } from 'src/hooks'
import { Button } from 'src/modules/Modal/shared'
import { CommunicationV2 } from 'src/types/CommunicationsV2'

const SendModalContent: React.FC<Props> = ({
  communications,
  hasAutomatedLetterMailingFeature,
  isCertifiedMail,
  isPlural,
  isSelectAllOn,
  locationIds,
  onCloseWindow,
  onCommunicationSelected,
  onCommunicationTypeSelected,
  selectedCommunication,
  selectedCommunicationType,
  stepNumber,
  toggleIsCertifiedMail
}) => {
  const { accountHasCertifiedMailFeature } = useFeatures()

  const isDownload =
    selectedCommunicationType === CommunicationTypes['Letter as PDF']

  const excludedCommTypes: string[] = useMemo(
    () => [
      CommunicationType.Email,
      ...((!hasAutomatedLetterMailingFeature && [CommunicationType.Letter]) ||
        [])
    ],
    [hasAutomatedLetterMailingFeature]
  )

  const communicationTypeOptions = useMemo(
    () =>
      Object.keys(CommunicationTypes)
        .filter(opt => !excludedCommTypes.includes(opt))
        .map(c => {
          if (c === 'Letter') {
            return { text: 'Letters sent by 120Water', value: c }
          }

          if (c === 'Letter as PDF') {
            return { text: 'Download letters & send yourself', value: c }
          }
          return { text: c, value: c }
        }),
    [excludedCommTypes]
  )

  const communicationOptions = useMemo(
    () =>
      communications.map(c => ({
        text: c.name,
        value: c.id
      })),
    [communications]
  )

  const onCommunicationTypeChange = useCallback(
    (_evt, data) => {
      onCommunicationTypeSelected(data)
    },
    [onCommunicationTypeSelected]
  )

  const onCommunicationSelectedChange = useCallback(
    (_evt, data) => {
      onCommunicationSelected(data)
    },
    [onCommunicationSelected]
  )

  if (stepNumber === 0) {
    return (
      <>
        <Dropdown
          fluid
          label="How do you want to send this communication?"
          onChange={onCommunicationTypeChange}
          options={communicationTypeOptions}
          placeholder="Select..."
          search
          value={selectedCommunicationType}
        />
        <LabelWrapper>
          <Label>Choose Letter Template</Label>
        </LabelWrapper>
        <Dropdown
          fluid
          onChange={onCommunicationSelectedChange}
          options={communicationOptions}
          placeholder="Select..."
          search
          value={selectedCommunication?.id}
        />
        {selectedCommunicationType === 'Letter' &&
          accountHasCertifiedMailFeature && (
            <CenteredTextContent>
              <RadioComponent
                checked={isCertifiedMail}
                label={'Send Letter Certified Mail'}
                onChange={toggleIsCertifiedMail}
                size={'small'}
                subLabel={
                  'Please note that sending Certified Mail will result in an additional charge per letter sent.'
                }
                subLabelColor={`${colors.error}`}
              />
            </CenteredTextContent>
          )}
      </>
    )
  }

  if (
    stepNumber === 1 &&
    isSelectAllOn &&
    (selectedCommunicationType === 'Letter' ||
      selectedCommunicationType === 'Letter as PDF')
  ) {
    return (
      <CenteredTextContent>
        <>
          <p>
            {`You are about to ${isDownload ? 'print' : 'send'} ${
              selectedCommunication?.name || ''
            } ${
              isDownload ? 'for' : 'to'
            } all of the locations selected. Are you sure?`}
          </p>
        </>
      </CenteredTextContent>
    )
  }

  if (stepNumber === 1) {
    return (
      <CenteredTextContent>
        <>
          <p>
            {`You are about to ${isDownload ? 'print' : 'send'} ${
              selectedCommunication?.name || ''
            } ${isDownload ? 'for' : 'to'}:`}
          </p>
          <p>
            <b>{`${locationIds.length} Location${isPlural ? 's' : ''}`}</b>
          </p>
        </>
      </CenteredTextContent>
    )
  }

  if (stepNumber === 2) {
    return (
      (!isDownload && (
        <CenteredTextContent>
          <p>
            Your letter{isPlural ? 's' : ''} {isPlural ? 'have' : 'has'} been
            put into the queue to send to the selected locations. Check the
            status on the{' '}
            <Link onClick={onCloseWindow} to={'/communications/letter-logs'}>
              Letter Log
            </Link>
            .
          </p>
          <Button onClick={onCloseWindow} variant="primary">
            Close Window
          </Button>
        </CenteredTextContent>
      )) || (
        <CenteredTextContent>
          <p>
            Hang tight! Your letter{isPlural ? 's' : ''} will download shortly
            to your device, this could take a few moments. Check the status on
            the{' '}
            <Link onClick={onCloseWindow} to={'/communications/letter-logs'}>
              Letter Log
            </Link>
            .
          </p>
          <Button onClick={onCloseWindow} variant="primary">
            Close Window
          </Button>
        </CenteredTextContent>
      )
    )
  }

  return null
}

export interface Props {
  communications: CommunicationV2[]
  hasAutomatedLetterMailingFeature: boolean
  isCertifiedMail: boolean
  isPlural: boolean
  isSelectAllOn?: boolean
  locationIds: number[]
  selectedCommunication?: CommunicationV2
  selectedCommunicationType?: CommunicationTypes
  stepNumber: number
  onCloseWindow: () => void
  onCommunicationSelected: (data: { value: string }) => void
  onCommunicationTypeSelected: (data: { value: CommunicationTypes }) => void
  toggleIsCertifiedMail: () => void
}

const CenteredTextContent = styled.div`
  text-align: center;
  margin-bottom: 2rem;
`

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`

const Label = styled.div`
  color: rgba(0, 36, 45, 0.5);
  font-weight: 800;
  font-size: 1rem;
`

export default memo(SendModalContent)
