import { Filter, FilterOption } from '@120wateraudit/envirio-components'
import {
  LeadServiceLineMaterialType,
  LeadServiceLinePriority,
  LeadServiceLineReplacementMethod,
  LeadServiceLineReplacementType,
  LeadServiceLineStatus,
  LeadServiceLineType,
  LeadServiceLineWaiverResponse,
  LocationProfileMaterialType,
  LocationProfileWaterMainMaterialType,
  OrderStatus
} from '@120wateraudit/envirio-components/dist/models'
import { sortBy } from 'lodash'
import { DropdownItemProps } from 'semantic-ui-react'

import { SampleStatus } from 'src/types/Sample'
import { SubmissionStatus } from 'src/types/Submission'
import {
  LocationProfileTierType,
  LCRRTierType
} from 'src/types/enums'

export enum DateInterval {
  All = 1000,
  'Last 24 Hours' = 0,
  'Last 7 Days' = 1,
  'Last 30 Days' = 2,
  'Last 12 Months' = 3
}

export const convertEnumToListItems = (
    enumToConvert: any,
    options?: {
      numericValues?: boolean
      sendToEnd?: string
      sortAlpha?: boolean
      sortAlphaProperty?: string
      disabled?: string[]
      sendAllDisabledToEnd?: boolean
      primacyExclude?: any
    }
): DropdownItemProps[] => {
  let listItems: DropdownItemProps[] = []

  const hasValueProperties = Boolean(
      Object.keys(enumToConvert).find(k => parseInt(k, 10) >= 0)
  )

  for (const e in enumToConvert) {
    if (enumToConvert.hasOwnProperty(e)) {
      const isValueProperty = !hasValueProperties || parseInt(e, 10) >= 0
        if (isValueProperty) {
          listItems.push({
            key: e,
            text: enumToConvert[e],
            value: options?.numericValues ? parseInt(e, 10) : e
          })
        }
      }
    }

  const sortProperty = options?.sortAlphaProperty
    ? options?.sortAlphaProperty
    : 'label'

  if (options?.sortAlpha) {
    listItems = sortBy(listItems, sortProperty)
  }

  if (options?.sendToEnd) {
    const index = listItems.findIndex(o => o.text === options?.sendToEnd)
    listItems.push(listItems.splice(index, 1)[0])
  }

  if (options?.disabled) {
    options.disabled.forEach(i => {
      const item = listItems.find(o => o.text === i)
      if (item) {
        item['disabled'] = true
      }
    })
  }

  if (options?.sendAllDisabledToEnd && options?.disabled) {
    const activeChoices = listItems.filter(
        i => !options?.disabled?.includes(i.text as string)
    )

    const disabledChoices = sortBy(
        listItems.filter(i => options?.disabled?.find(o => o === i.text)),
        'text'
    )

    listItems = [...activeChoices, ...disabledChoices]
  }

  if (options?.primacyExclude) {
    listItems = listItems.filter(
      ({ text }) => !options.primacyExclude.includes(text)
    )
  }

  return listItems
}

export const getLabelRendererForOptions = (
    options
): ((f: Filter) => string) => {
  const getLabel = (f: Filter) =>
      options.filter(s => s.value === f.value)[0].text

  return getLabel
}

export const dateIntervalListOptions = convertEnumToListItems(DateInterval)
export const sampleStatusOptions = Object.keys(SampleStatus).map(key => ({
  key,
  text: key,
  value: key
}))

export const submissionStatusOptions = Object.keys(SubmissionStatus).map(
    key => ({
      key,
      text: SubmissionStatus[key],
      value: key
    })
)

export const leadServiceLineMaterialOptions = convertEnumToListItems(
    LeadServiceLineMaterialType,
    { sendToEnd: 'Unknown' }
)

export const leadServiceLineReplacementMethodOptions = convertEnumToListItems(
    LeadServiceLineReplacementMethod
)

export const leadServiceLineReplacementTypeOptions = convertEnumToListItems(
    LeadServiceLineReplacementType
)

export const leadServiceLineWaiverResponseOptions = convertEnumToListItems(
    LeadServiceLineWaiverResponse
)

export const leadServiceLinePriorityOptions = convertEnumToListItems(
    LeadServiceLinePriority
)

export const leadServiceLineStatusListOptions = convertEnumToListItems(
    LeadServiceLineStatus
)

export const kitOrderStatusListOptions = convertEnumToListItems(OrderStatus)

export const lslReplacementMethodListOptions = convertEnumToListItems(
    LeadServiceLineReplacementMethod
)

export const lslTypeListOptions = convertEnumToListItems(
    LeadServiceLineReplacementType
)

export const lslLineTypeOptions = convertEnumToListItems(LeadServiceLineType)

export const lslWaiverReponse = convertEnumToListItems(
    LeadServiceLineWaiverResponse
)

export const locationProfileMaterialTypes = convertEnumToListItems(
    LocationProfileMaterialType
)

export const locationProfileWaterMainMaterialTypes = convertEnumToListItems(
    LocationProfileWaterMainMaterialType
)

export const locationProfileTierTypes = convertEnumToListItems(
    LocationProfileTierType
)

export const locationProfileTierOptions = convertEnumToListItems(
    LocationProfileTierType
).map(o => {
  o.text = typeof o.text === 'string' ? o.text.replace('Tier ', '') : o.text
  return o
})

export const lcrrTierOptions = convertEnumToListItems(LCRRTierType).map(o => {
  o.text = typeof o.text === 'string' ? o.text.replace('Tier', '') : o.text
  return o
})

export const validInvalidUnknownOptions = [
  {
    key: 'valid',
    text: 'Valid',
    value: '1'
  },
  {
    key: 'invalid',
    text: 'Invalid',
    value: '0'
  },
  {
    key: 'false',
    text: '--',
    value: 'Unknown'
  }
]

export const leadPredictionListOptions = [
  {
    key: 'verifiedLead',
    text: 'Verified Lead',
    value: 'verifiedLead'
  },
  {
    key: 'high',
    text: 'High Probability Lead',
    value: 'high'
  },
  {
    key: 'medium',
    text: 'Medium Probability Lead',
    value: 'medium'
  },
  {
    key: 'low',
    text: 'Low Probability Lead',
    value: 'low'
  },
  {
    key: 'verifiedNonLead',
    text: 'Verified Non-Lead',
    value: 'verifiedNonLead'
  }
]

export const epaReportingCategoryOptions = [
  {
    key: 'Galvanized Requiring Replacement',
    text: 'Galvanized Requiring Replacement',
    value: 'Galvanized Requiring Replacement'
  },
  {
    key: 'Lead Status Unknown',
    text: 'Lead Status Unknown',
    value: 'Lead Status Unknown'
  },
  {
    key: 'Lead',
    text: 'Lead',
    value: 'Lead'
  },
  {
    key: 'Non-lead',
    text: 'Non-lead',
    value: 'Non-lead'
  }
]

export const trueFalseListOptions = [
  {
    key: 'false',
    text: 'False',
    value: '0'
  },
  {
    key: 'true',
    text: 'True',
    value: '1'
  }
]

export const YES_NO_DROPDOWN_OPTIONS = [
  {
    key: 'yes',
    text: 'Yes',
    value: '1'
  },
  {
    key: 'no',
    text: 'No',
    value: '0'
  }
]

export const BOOLEAN_OPTIONS = [
  {
    key: 'yes',
    text: 'Yes',
    value: true
  },
  {
    key: 'no',
    text: 'No',
    value: false
  }
]

export const trueFalseLabelRenderer = (f: Filter): string => {
  return f.value === '1' ? 'True' : 'False'
}

export const convertStringEnumToDropdownOptions = (
    o: Record<string, unknown>
) => {
  return Object.keys(o).map((key, index) => ({
    key: key + '-' + index,
    text: key,
    value: o[key]
  }))
}

export const convertStringEnumToDropdownOptionsWithText = (
  o: Record<string, unknown>,
  primacyExclude: any
) => {
  return Object.keys(o)
    .map((key, index) => ({
    key: key + '-' + index,
    text: o[key],
    value: o[key]
  }))
    .filter(({ value }) => !primacyExclude.includes(value))
}

export const convertToSmartListOptions = (
    opts: DropdownItemProps[]
): FilterOption[] =>
    opts.map((opt: any) => ({
      text: typeof opt.label === 'string' ? opt.label : opt.text || '',
      value: opt.value
    }))
