import {
  Activity,
  ActivityType,
  LeadServiceLine,
  Note,
  PWSLocation,
  PWSProgram,
  PWSSampleStatusHistory,
  PWSSamplingEvent,
  ProtocolDefinition,
  Sku
} from '@120wateraudit/envirio-components/dist/models'
import { Action, combineReducers } from 'redux'

import users from 'src/modules/Users/slice'
import {
  accountManagementApi,
  accountManagementGQL,
  attachmentsApi,
  communicationsTwoApi,
  customFieldsApi,
  fileProcessorApi,
  geoserverApi,
  pwsApi,
  pwsApiV2,
  pwsApiV3,
  pwsGQL,
  stateSubmissionApi,
  entityServiceApi
} from 'src/services'
import { PWSSample } from 'src/types/Sample'
import { ProgramTotals, SamplesByMonth } from 'src/types/Statistics'
import { Geocoding } from '../types/Geocoding'
import activities from './activities'
import activityTypes from './activityTypes'
import batchDownloads, { DownloadState } from './batchDownloads'
import crossEntityReducer from './crossEntity'
import geocoding from './geocoding'
import { DetailsReducerState } from './helpers/DetailsReducers'
import { IndexReducerState } from './helpers/IndexReducer'
import leadServiceLine from './leadServiceLine'
import leadServiceLines from './leadServiceLines'
import location from './location'
import locations from './locations'
import polling, { PollingState } from './polling'
import program from './program'
import programTotals from './programTotals'
import programs from './programs'
import protocols from './protocols'
import sample from './sample'
import sampleStatusHistory from './sampleStatusHistory'
import samples from './samples'
import samplingEvent from './samplingEvent'
import samplingEvents from './samplingEvents'
import skus from './skus'
import weeklyStats, { WeeklyStatsDatasetsState } from './weeklyStats'

export interface ApplicationState {
  [accountManagementApi.reducerPath]: ReturnType<
    typeof accountManagementApi.reducer
  >
  [accountManagementGQL.reducerPath]: ReturnType<
    typeof accountManagementGQL.reducer
  >
  [attachmentsApi.reducerPath]: ReturnType<typeof attachmentsApi.reducer>
  [customFieldsApi.reducerPath]: ReturnType<typeof customFieldsApi.reducer>
  [communicationsTwoApi.reducerPath]: ReturnType<
    typeof communicationsTwoApi.reducer
  >
  [fileProcessorApi.reducerPath]: ReturnType<typeof fileProcessorApi.reducer>
  [geoserverApi.reducerPath]: ReturnType<typeof geoserverApi.reducer>
  [pwsApi.reducerPath]: ReturnType<typeof pwsApi.reducer>
  [pwsApiV2.reducerPath]: ReturnType<typeof pwsApiV2.reducer>
  [pwsApiV3.reducerPath]: ReturnType<typeof pwsApiV3.reducer>
  [pwsGQL.reducerPath]: ReturnType<typeof pwsGQL.reducer>
  [stateSubmissionApi.reducerPath]: ReturnType<
    typeof stateSubmissionApi.reducer
  >
  [entityServiceApi.reducerPath]: ReturnType<typeof entityServiceApi.reducer>
  activities: IndexReducerState<Activity>
  activityTypes: IndexReducerState<ActivityType>
  batchDownloads: DownloadState
  geocoding: Geocoding[]
  leadServiceLine: DetailsReducerState<LeadServiceLine>
  leadServiceLines: IndexReducerState<LeadServiceLine>
  location: DetailsReducerState<PWSLocation>
  locations: IndexReducerState<PWSLocation>
  notes: IndexReducerState<Note>
  polling: PollingState
  program: DetailsReducerState<PWSProgram>
  programTotals: IndexReducerState<ProgramTotals>
  programs: IndexReducerState<PWSProgram>
  protocols: IndexReducerState<ProtocolDefinition>
  sample: DetailsReducerState<PWSSample>
  sampleStatusHistory: DetailsReducerState<PWSSampleStatusHistory>
  samples: IndexReducerState<PWSSample>
  samplesByMonth: IndexReducerState<SamplesByMonth>
  samplingEvent: DetailsReducerState<PWSSamplingEvent>
  samplingEvents: IndexReducerState<PWSSamplingEvent>
  skus: IndexReducerState<Sku>
  users: ReturnType<typeof users>
  weeklyStats: WeeklyStatsDatasetsState
}

const appReducer = combineReducers({
  [accountManagementApi.reducerPath]: accountManagementApi.reducer,
  [accountManagementGQL.reducerPath]: accountManagementGQL.reducer,
  [attachmentsApi.reducerPath]: attachmentsApi.reducer,
  [customFieldsApi.reducerPath]: customFieldsApi.reducer,
  [communicationsTwoApi.reducerPath]: communicationsTwoApi.reducer,
  [fileProcessorApi.reducerPath]: fileProcessorApi.reducer,
  [geoserverApi.reducerPath]: geoserverApi.reducer,
  [pwsApi.reducerPath]: pwsApi.reducer,
  [pwsApiV2.reducerPath]: pwsApiV2.reducer,
  [pwsApiV3.reducerPath]: pwsApiV3.reducer,
  [pwsGQL.reducerPath]: pwsGQL.reducer,
  [stateSubmissionApi.reducerPath]: stateSubmissionApi.reducer,
  [entityServiceApi.reducerPath]: entityServiceApi.reducer,
  activities,
  activityTypes,
  batchDownloads,
  geocoding,
  leadServiceLine,
  leadServiceLines,
  location,
  locations,
  polling,
  program,
  programTotals,
  programs,
  protocols,
  sample,
  sampleStatusHistory,
  samples,
  samplingEvent,
  samplingEvents,
  skus,
  users,
  weeklyStats
})

// This root reducer confuses the type that `configureStore` infers
const rootReducer = (state: ApplicationState, action: Action) => {
  const intermediateState = appReducer(state, action)
  return crossEntityReducer(intermediateState, action)
}

export default rootReducer
