import React, { useMemo } from 'react'

import { SendOption } from 'src/modules/Kits/modal/SendResultsModal'
import { Button } from 'src/modules/Modal/shared'
import styled from 'styled-components'

export interface Props {
  activeStepIndex: number
  closeModal: () => void
  isNextDisabled: boolean
  onNavigation: (navigation: 'back' | 'next') => void
  sendOption?: SendOption
  onCompleted: () => void
}

const Footer: React.FC<Props> = ({
  activeStepIndex,
  closeModal,
  isNextDisabled,
  onNavigation,
  sendOption,
  onCompleted
}) => {
  const completeButtonText = useMemo((): string => {
    return sendOption === SendOption.USPS ? 'Confirm & Send' : 'Download PDFs'
  }, [sendOption])

  if (activeStepIndex === 0) {
    return (
      <ButtonContainer>
        <ButtonGrid>
          <Button onClick={closeModal}>Cancel</Button>
          <Button
            variant="primary"
            disabled={isNextDisabled}
            onClick={() => onNavigation('next')}>
            Next
          </Button>
        </ButtonGrid>
      </ButtonContainer>
    )
  }

  if (activeStepIndex === 1) {
    return (
      <ButtonContainer>
        <ButtonGrid>
          <Button onClick={() => onNavigation('back')} fluid>
            Back
          </Button>
          <Button
            variant="primary"
            disabled={isNextDisabled}
            onClick={() => onNavigation('next')}>
            Next
          </Button>
        </ButtonGrid>
      </ButtonContainer>
    )
  }

  if (activeStepIndex === 2) {
    return (
      <ButtonContainer>
        <ButtonGrid>
          <Button onClick={() => onNavigation('back')} fluid>
            Back
          </Button>
          <Button
            variant="primary"
            disabled={isNextDisabled}
            onClick={onCompleted}>
            {completeButtonText}
          </Button>
        </ButtonGrid>
      </ButtonContainer>
    )
  }

  if (activeStepIndex === 3) {
    return (
      <ButtonContainer>
        <ButtonGrid>
          <Button variant="primary" onClick={closeModal}>
            Close Window
          </Button>
        </ButtonGrid>
      </ButtonContainer>
    )
  }

  return null
}

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  grid-row-gap: 1rem;
  justify-items: center;
`

export default React.memo(Footer)
