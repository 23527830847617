import gql from 'graphql-tag'
import { CommunicationInput } from 'src/containers/Communications/EditorSuite/data-access'

export const COMMUNICATION_PREVIEW = gql`
  query communicationPreview(
    $accountId: Int!
    $communication: CommunicationInput!
    $content: String!
  ) {
    communicationPreview(
      accountId: $accountId
      communication: $communication
      content: $content
    )
  }
`

export interface CommunicationPreviewData {
  communicationPreview: string
}

export interface CommunicationPreviewVariables {
  accountId: number
  communication: CommunicationInput
  content: string
}
