import React from 'react'
import { Bounce, ToastOptions, toast } from 'react-toastify'

import { DownloadStartedToast } from 'src/components/Toasts/DownloadStarted'

export const DEFAULT_TOAST: ToastOptions = {
  autoClose: 2500,
  hideProgressBar: true,
  position: 'bottom-right',
  theme: 'colored',
  transition: Bounce,
  type: 'info'
}

export const LONG_TOAST: ToastOptions = {
  ...DEFAULT_TOAST,
  autoClose: 5000
}

const DOWNLOAD_TOAST: ToastOptions = {
  ...LONG_TOAST,
  theme: 'light',
  icon: false,
  closeOnClick: true,
  pauseOnHover: true
}

export const toastSuccess = (message: string) =>
  toast(message, {
    ...DEFAULT_TOAST,
    type: 'success'
  })

export const downloadingToast = (message: React.ReactNode) =>
  toast(message, DOWNLOAD_TOAST)

export const toastError = (message: string) =>
  toast(message, {
    ...DEFAULT_TOAST,
    type: 'error'
  })

export const toastIndefiniteError = (message: string) =>
  toast(message, {
    ...DEFAULT_TOAST,
    type: 'error',
    autoClose: false
  })

export const displayCustomFieldSuccess = (field: string, entity: any) => {
  let friendly: string = entity

  if (entity === 'LeadServiceLines') {
    friendly = 'Lead Service Lines'
  } else if (entity === 'PwsSamples') {
    friendly = 'Samples'
  } else if (entity === 'PwsSampleResults') {
    friendly = 'Sample Results'
  }
  const message = `Successfully created new ${field} field for ${friendly}`
  return toastSuccess(message)
}

interface DownloadToastProps {
  type: 'comm' | 'export'
}

export const displayDownloadStarted = ({ type }: DownloadToastProps) => {
  return downloadingToast(<DownloadStartedToast type={type} />)
}
