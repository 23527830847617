import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { Middleware } from 'redux'
import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga'

import { __DEV__ } from 'src/constants'
import reducer from 'src/reducers'
import sagas from 'src/sagas'
import {
  accountManagementApi,
  accountManagementGQL,
  attachmentsApi,
  communicationsTwoApi,
  customFieldsApi,
  fileProcessorApi,
  geoserverApi,
  pwsApi,
  pwsApiV2,
  pwsApiV3,
  pwsGQL,
  stateSubmissionApi,
  entityServiceApi
} from 'src/services'

const sagaMiddleware = createSagaMiddleware()
const middlewares: Middleware[] = [sagaMiddleware]

if (__DEV__) {
  middlewares.push(logger)
}

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      ...middlewares,
      accountManagementApi.middleware,
      accountManagementGQL.middleware,
      attachmentsApi.middleware,
      communicationsTwoApi.middleware,
      customFieldsApi.middleware,
      fileProcessorApi.middleware,
      geoserverApi.middleware,
      pwsApi.middleware,
      pwsApiV2.middleware,
      pwsApiV3.middleware,
      pwsGQL.middleware,
      stateSubmissionApi.middleware,
      entityServiceApi.middleware
    )
})

sagaMiddleware.run(sagas)
setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch
// Because of the way we apply a "cross-entity reducer" this type is incorrect
export type RootState = ReturnType<typeof store.getState>

export default store
