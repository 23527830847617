// Water Coolors
export const AQUA_BLUE = '#00B5E2'
export const DARK_BLUE = '#0033A0'
export const TERRA_GREEN = '#18C57E'
export const IGNIS_RED = '#F7685B'
export const VENTUS_YELLOW = '#FFB946'
export const DARK_GRAY_BLUE = '#2F3747'
export const DARK_GRAY = '#707683'
export const GRAY = '#90A0B7'
export const LIGHT_GRAY = '#C2CFE0'
export const LIGHTEST_GRAY = '#D4D4D4'
export const BLACK = '#192A3E'
export const WHITE = '#FFFFFF'

// Themes
export const PRIMARY = AQUA_BLUE
export const SECONDARY = DARK_BLUE
export const TERTIARY = TERRA_GREEN
export const QUATERNARY = LIGHT_GRAY
export const TABLE_TEXT_GRAY = DARK_GRAY
export const TABLE_GRAY_LIGHT = '#F5F5F5'
export const ICON_GRAY = LIGHT_GRAY

// Material Class Colors
export const LEAD = IGNIS_RED
export const GALVANIZED = '#FFC720'
export const NONLEAD = AQUA_BLUE
export const UNKNOWN = DARK_BLUE

// Program Status Colors
export const STATUS_COLORS = {
  Assigned: DARK_BLUE,
  'At Lab': '#7013FE',
  Completed: 'rgba(0, 205, 135, 1)',
  Delivered: DARK_BLUE,
  Invalidated: IGNIS_RED,
  'No Action Required': '#B0F2D7',
  Ordered: '#00B5E1',
  'Post-Sampling': '#009CC2',
  'Pre-Sampling': 'rgba(0, 205, 135, 1)',
  Replaced: '#8576EC',
  'Results Ready': '#00CD87',
  'Return To Sender': IGNIS_RED,
  Scheduled: '#2CD4BE',
  Shipped: '#00B5E1',
  Unknown: IGNIS_RED
}
