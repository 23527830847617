import CreateSavedViewModal from 'src/components/Table/SavedViews/CreateSavedViewModal'
import DeleteSavedViewModal from 'src/components/Table/SavedViews/DeleteSavedViewModal'
import { MODALS as ACTIVITY_MODALS } from 'src/modules/Activities/modals'
import { MODALS as ATTACHMENT_MODALS } from 'src/modules/Attachments/modals'
import UploadDocuments from 'src/modules/Documents/UploadModal'
import EditSamplingEvent from 'src/modules/Events/Edit'
import { MODALS as EVENT_MODALS } from 'src/modules/Events/Modals'
import NewSamplingEvent from 'src/modules/Events/New'
import SendResultsModal from 'src/modules/Kits/modal/SendResultsModal'
import KitsCommunicationModal from 'src/modules/Kits/modal/KitsCommunicationModal'
import { MODALS as LOCATION_MODALS } from 'src/modules/Locations/modals'
import BulkImportModal from 'src/modules/Modal/BulkImportModal'
import CreateLetterTemplateModal from 'src/modules/Modal/Communications/CreateLetterTemplateModal'
import CommsTwoLetterTemplateModal from 'src/modules/Modal/Communications/CommsTwoLetterTemplateModal'
import EventAssignmentModal from 'src/modules/Modal/EventAssignment/EventAssignmentModal'
import { MODALS as NAV_MODALS } from 'src/modules/Navigation/Modals'
import { MODALS as PROGRAM_MODALS } from 'src/modules/Programs/modals'
import CompleteSamplesModal from 'src/modules/Samples/modals/CompleteSamplesModal'
import InvalidateSamplesModal from 'src/modules/Samples/modals/InvalidateSamplesModal'
import MoveSamplesModal from 'src/modules/Samples/modals/MoveSamplesModal'
import { MODALS as SERVICELINE_MODALS } from 'src/modules/ServiceLines/modals'
import AddDataToEditorModal from './Communications/AddDataToEditorModal'
import CopyCommunicationModal from './Communications/CopyCommunicationModal'
import ConfirmationModal from './ConfirmationModal'
import SubmissionPeriodActivityModal from 'src/modules/ServiceLines/StateInventory/ActivityModal'
import LetterLogDownloadModal from 'src/components/LetterLogDownloadModal'

export const MODALS = {
  ...NAV_MODALS,
  ...PROGRAM_MODALS,
  ...EVENT_MODALS,
  ...LOCATION_MODALS,
  ...SERVICELINE_MODALS,
  ...ACTIVITY_MODALS,
  ...ATTACHMENT_MODALS,
  addDataToEditor: AddDataToEditorModal,
  bulkImportModal: BulkImportModal,
  completeSamples: CompleteSamplesModal,
  confirm: ConfirmationModal,
  copyCommunication: CopyCommunicationModal,
  createLetterTemplate: CreateLetterTemplateModal,
  commsTwoLetterTemplateModal: CommsTwoLetterTemplateModal,
  createSamplingEvent: NewSamplingEvent,
  createSavedView: CreateSavedViewModal,
  deleteSavedView: DeleteSavedViewModal,
  editSamplingEvent: EditSamplingEvent,
  eventAssignmentModal: EventAssignmentModal,
  invalidateSamples: InvalidateSamplesModal,
  moveSamples: MoveSamplesModal,
  sendResultsModal: SendResultsModal,
  kitsCommunicationModal: KitsCommunicationModal,
  showSubmissionActivity: SubmissionPeriodActivityModal,
  uploadDocuments: UploadDocuments,
  letterLogDownloadModal: LetterLogDownloadModal
}
