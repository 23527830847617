import React, { useCallback } from 'react'
import {
  Dropdown as SUIRDropdown,
  DropdownItemProps,
  DropdownProps
} from 'semantic-ui-react'
import styled from 'styled-components'

import { ValidFilterValue } from '../types'
import RemoveFilterButton from './RemoveFilterButton'
import { FilterLabel } from './shared'

interface Props {
  defaultValue?: ValidFilterValue
  label: string
  multiple?: boolean
  options: DropdownItemProps[]
  value?: ValidFilterValue
  onChange(value?: null | ValidFilterValue): void
  removeFilter: () => void
  scrolling?: boolean
}

const ListSelectionFilter = ({
  defaultValue,
  label,
  multiple = false,
  onChange,
  options,
  removeFilter,
  scrolling = false,
  value
}: Props): JSX.Element => {
  const onDropdownChange = useCallback(
    (_, { value }: DropdownProps) => {
      if (Array.isArray(value) && value.length === 0) {
        onChange(undefined)
      } else {
        onChange(value)
      }
    },
    [onChange]
  )
  return (
    <StyledFilterLabel>
      <span>{label}</span>
      <Dropdown
        direction="left"
        multiple={multiple}
        onChange={onDropdownChange}
        options={options}
        placeholder="All"
        value={value ?? defaultValue}
        scrolling={scrolling}
      />
      <RemoveFilterButton removeFilter={removeFilter} />
    </StyledFilterLabel>
  )
}

const Dropdown = styled(SUIRDropdown)`
  &.multiple.dropdown {
    padding: 0;
    & > .text {
      margin: 0;
    }
    & > .label {
      margin: 0 4px 0 0;
      vertical-align: baseline;
    }
  };

  &.ui.dropdown>.menu {
    max-height: 400px;
    overflow-y: scroll;
  }
` as typeof SUIRDropdown

const StyledFilterLabel = styled(FilterLabel)`
  &&& {
    max-height: fit-content;
  }
`

export default ListSelectionFilter
