import { Loader } from '@120wateraudit/envirio-components'
import React, { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  AccountType,
  CommunicationStatus,
  CommunicationType,
  TriggerType
} from '@120wateraudit/communications-types'
import { useQuery } from '@apollo/client'
import { useDispatch } from 'react-redux'

import { pwsClient } from 'src/apollo-clients'
import { addBatchDownload } from 'src/actions/batchDownloads'
import { startPolling } from 'src/actions/polling'
import { ReactComponent as CheckIcon } from 'src/assets/images/Check.svg'
import { ReactComponent as LetterIcon } from 'src/assets/images/Letter.svg'
import { ReactComponent as HazardIcon } from 'src/assets/images/Hazard.svg'
import { useFeatures } from 'src/hooks'
import { useToggle } from 'src/hooks/useToggle'
import {
  ButtonsWrapper,
  ErrorMessage,
  ModalContent,
  ModalHeading,
  Wrapper
} from 'src/modules/Modal/shared'
import { ModalWrapper } from 'src/modules/Samples/modals/CompleteSamplesModal/shared'
import SendModalContent from './SendModalContent'
import SendModalFooter from './SendModalFooter'
import { SupportLink } from 'src/components/SupportLink'
import { NoTemplatesWarning } from 'src/components/Communications/NoTemplatesWarning'
import { BulkActionType } from 'src/types/enums/BulkActionType'
import { usePerformBulkActionByTypeMutation } from 'src/modules/ServiceLines/dataAccess'
import { Link } from 'react-router-dom'
import {
  AccountSoftwarePackage,
  GET_PACKAGE_NAME_FOR_ACCOUNT_QUERY
} from 'src/containers/Communications/Dashboard/dataAccess'
import { SoftwarePackageName } from 'src/types/enums/SoftwarePackageName'
import { CommunicationV2 } from 'src/types/CommunicationsV2'
import { useGetAllCommunicationsQuery } from 'src/services/communications-two-api'

const AssetSendCommunicationModal: React.FC<Props> = ({
  accountId,
  bulkActionType,
  isSelectAllOn,
  locationIds: baseLocationIds,
  onClose,
  onCompletion,
  paramsURL,
  selectAllCount
}) => {
  const { accountHasCommsAutomatedMailingFeature } = useFeatures()
  const locationIds = useRef(baseLocationIds)
  const [isCertifiedMail, toggleIsCertifiedMail] = useToggle(false)
  const [selectedCommunication, setSelectedCommunication] = useState<
    undefined | CommunicationV2
  >(undefined)
  const [selectedCommunicationType, setSelectedCommunicationType] = useState<
    undefined | CommunicationTypes
  >(undefined)
  const [stepNumber, setStepNumber] = useState<number>(0)
  const [errorMessage, setErrorMessage] = useState<undefined | string>()
  const zIndex = 1000
  const dispatch = useDispatch()

  const { data: accountSoftwarePackage } = useQuery<AccountSoftwarePackage>(
    GET_PACKAGE_NAME_FOR_ACCOUNT_QUERY,
    {
      client: pwsClient,
      variables: { accountId: accountId },
      skip: !accountId
    }
  )

  const { data: commsData, isLoading: isLoadingComms } =
    useGetAllCommunicationsQuery({
      searchParams: {
        activeFilters: {
          'communications.communicationType': 'Letter',
          'communications.status': CommunicationStatus.Active,
          'communications.triggerType': TriggerType.AdHocLocation
        },
        page: 1,
        pageSize: 10000
      }
    })

  const communications = commsData?.data ?? []

  const clearState = useCallback(() => {
    setSelectedCommunication(undefined)
    setSelectedCommunicationType(undefined)
    setStepNumber(0)
  }, [])

  const onNextClicked = useCallback(() => {
    if (stepNumber !== 2 && selectedCommunication) {
      setStepNumber(stepNumber + 1)
    }
  }, [stepNumber, selectedCommunication, selectedCommunicationType])

  const onBackClicked = useCallback(() => {
    if (stepNumber === 1) {
      setStepNumber(0)
    }
  }, [stepNumber])

  const [sendBulkAssetCommunications, { isLoading: isBulkLoading, data }] =
    usePerformBulkActionByTypeMutation()

  const { batchId } = data ?? {}

  const onSend = useCallback(async () => {
    if (selectedCommunication !== undefined && bulkActionType && paramsURL) {
      try {
        await sendBulkAssetCommunications({
          bulkActionType,
          accountType: AccountType.PWS,
          communicationId: selectedCommunication.id,
          isCertifiedMail,
          paramsURL,
          locationIds: isSelectAllOn ? undefined : locationIds.current
        }).unwrap()

        if (onCompletion) onCompletion()
        onNextClicked()
      } catch (error) {
        const errorMessage = error.data?.errors[0].detail
          ? error.data?.errors[0].detail
          : 'An error occurred. Please try again.'

        setErrorMessage(errorMessage)
      }
    }
  }, [
    accountId,
    bulkActionType,
    isSelectAllOn,
    isCertifiedMail,
    locationIds,
    onCompletion,
    onNextClicked,
    paramsURL,
    sendBulkAssetCommunications,
    selectedCommunication
  ])

  const onCloseWindow = useCallback(() => {
    clearState()
    onClose()
  }, [clearState, onClose])

  const onDownload = useCallback(async () => {
    if (selectedCommunication !== undefined && bulkActionType && paramsURL) {
      try {
        await sendBulkAssetCommunications({
          bulkActionType,
          accountType: AccountType.PWS,
          communicationId: selectedCommunication.id,
          download: true,
          paramsURL,
          locationIds: isSelectAllOn ? undefined : locationIds.current
        }).unwrap()

        if (batchId) {
          dispatch(addBatchDownload({ batchId }))
          dispatch(startPolling())
        }
        onNextClicked()
      } catch (error) {
        const errorMessage = error.data?.errors[0].detail
          ? error.data?.errors[0].detail
          : 'An error occurred. Please try again.'

        setErrorMessage(errorMessage)
      }
    }
  }, [
    batchId,
    bulkActionType,
    dispatch,
    onNextClicked,
    paramsURL,
    sendBulkAssetCommunications,
    selectedCommunication
  ])

  const onCommunicationSelected = useCallback(
    ({ value }: { value: string }) => {
      const selected = communications.find(c => c.id === value)
      setSelectedCommunication(selected)
    },
    [communications]
  )

  const onCommunicationTypeSelected = useCallback(
    ({ value }: { value: CommunicationTypes }) => {
      setSelectedCommunicationType(CommunicationTypes[value])
    },
    []
  )

  const isPlural =
    isSelectAllOn && selectAllCount
      ? selectAllCount > 1
      : baseLocationIds.length > 1
  const renderHeading = useCallback(() => {
    if (errorMessage) {
      return 'Error Occurred.'
    }

    if (selectedCommunicationType === CommunicationTypes['Letter as PDF']) {
      if (stepNumber === 2) {
        return 'Completed!'
      }

      if (stepNumber === 1) {
        return `Print Communication${isPlural ? 's' : ''}`
      }
    }

    if (
      stepNumber === 1 &&
      selectedCommunicationType === 'Letter' &&
      isSelectAllOn
    ) {
      return `Send Letters`
    }

    if (stepNumber === 2) {
      return 'Completed!'
    }

    return `Send Communication${isPlural ? 's' : ''}`
  }, [
    errorMessage,
    isPlural,
    isSelectAllOn,
    stepNumber,
    selectedCommunicationType
  ])

  const renderIcon = useCallback(() => {
    if (errorMessage) {
      return HazardIcon
    }
    if (stepNumber === 2) {
      return CheckIcon
    }

    return LetterIcon
  }, [errorMessage, isSelectAllOn, stepNumber, selectedCommunicationType])

  if (
    !accountHasCommsAutomatedMailingFeature &&
    accountSoftwarePackage?.accountSoftwarePackageName ===
      SoftwarePackageName.PwsPortal
  ) {
    return (
      <ModalWrapper style={{ gap: '0' }}>
        <ModalHeading>
          <h4 style={{ fontSize: '2.25rem' }}>{renderHeading()}</h4>
        </ModalHeading>
        <StyledHeader style={{ marginTop: '0px' }}>
          This feature is not included in the PWS Portal account procured on
          your behalf.
          <br />
          <br />
          If you would like to learn more about this feature, please reach out
          to{' '}
          <EmailLink href="mailto:support@120water.com">
            support@120water.com
          </EmailLink>
          . Communication templates can be downloaded in the{' '}
          <Link onClick={onClose} to={`/administration/resources`}>
            Resources
          </Link>{' '}
          tab on the Account Settings page.
        </StyledHeader>
      </ModalWrapper>
    )
  }

  if (!accountHasCommsAutomatedMailingFeature) {
    return (
      <ModalWrapper>
        <ModalHeading>
          <h5>{renderHeading()}</h5>
        </ModalHeading>
        <StyledHeader>
          Want to use this feature?{' '}
          <SupportLink>Reach out to support</SupportLink> to upgrade your
          package!
        </StyledHeader>
      </ModalWrapper>
    )
  }

  if (!isLoadingComms && communications && communications.length < 1) {
    return <NoTemplatesWarning onClose={onCloseWindow} />
  }

  if (isLoadingComms) {
    return <Loader />
  }

  return (
    <ModalWrapper>
      <Wrapper
        style={{
          overlay: {
            zIndex: `${zIndex}`
          }
        }}>
        <ModalHeading>
          <Icon as={renderIcon()} />
          <h5>{renderHeading()}</h5>
        </ModalHeading>
        <React.Suspense fallback={<Loader />}>
          {(!isLoadingComms && (
            <>
              {!errorMessage && (
                <ModalContent>
                  <SendModalContent
                    communications={communications}
                    hasAutomatedLetterMailingFeature={
                      accountHasCommsAutomatedMailingFeature
                    }
                    isCertifiedMail={isCertifiedMail}
                    isPlural={isPlural}
                    isSelectAllOn={isSelectAllOn}
                    locationIds={baseLocationIds}
                    onCloseWindow={onCloseWindow}
                    onCommunicationSelected={onCommunicationSelected}
                    onCommunicationTypeSelected={onCommunicationTypeSelected}
                    selectedCommunication={selectedCommunication}
                    selectedCommunicationType={selectedCommunicationType}
                    stepNumber={stepNumber}
                    toggleIsCertifiedMail={toggleIsCertifiedMail}
                  />
                </ModalContent>
              )}
              {errorMessage && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <ErrorMessage style={{ fontSize: '14px' }}>
                    {errorMessage}
                  </ErrorMessage>
                  {errorMessage.includes('Bulk send') && (
                    <div
                      style={{
                        marginBottom: '15px',
                        fontSize: '14px'
                      }}>
                      Please do a quick check on the{' '}
                      <Link
                        onClick={onCloseWindow}
                        to={'/communications/letter-logs'}>
                        Letter Log
                      </Link>{' '}
                      page to make sure no letters were sent out.
                    </div>
                  )}
                </div>
              )}
              <ButtonsWrapper>
                <SendModalFooter
                  disabledForError={!!errorMessage}
                  isSaving={isLoadingComms || isBulkLoading}
                  isSelectAllOn={isSelectAllOn}
                  locationIds={baseLocationIds}
                  onBack={onBackClicked}
                  onClose={onClose}
                  onDownload={onDownload}
                  onNext={onNextClicked}
                  onSend={onSend}
                  selectedCommunication={selectedCommunication}
                  selectedCommunicationType={selectedCommunicationType}
                  stepNumber={stepNumber}
                />
              </ButtonsWrapper>
            </>
          )) || <Loader />}
        </React.Suspense>
      </Wrapper>
    </ModalWrapper>
  )
}

interface Props {
  accountId: number
  bulkActionType?: BulkActionType
  isSelectAllOn?: boolean
  locationIds: number[]
  onClose: () => void
  onCompletion: () => void
  paramsURL?: string
  selectAllCount?: number
}

enum PDFSelection {
  'Letter as PDF' = 'Letter as PDF'
}

const CommunicationTypes = Object.assign({}, CommunicationType, PDFSelection)

// eslint-disable-next-line @typescript-eslint/no-redeclare
type CommunicationTypes = keyof typeof CommunicationTypes

const Icon = styled.img`
  width: 55px;
  height: 55px;
`
const StyledHeader = styled.h4`
  font-weight: 500;
`

const EmailLink = styled.a`
  text-decoration: none;
`

export default React.memo(AssetSendCommunicationModal)
